// import logo from './logo.svg';
import ChatApp from './components/ChatApp';
import './App.css';

function App() {
  return (

    <ChatApp />

  );
}

export default App;
