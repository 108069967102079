import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { getDatabase, ref, onValue, push } from 'firebase/database';
import './css/ChatApp.css';

const firebaseConfig = {
    apiKey: "AIzaSyAWKuux6Pnz_gI3UgpuTjLGK3xM51a8q0Y",
    authDomain: "chatapp-ee2d2.firebaseapp.com",
    projectId: "chatapp-ee2d2",
    storageBucket: "chatapp-ee2d2.appspot.com",
    messagingSenderId: "719716193156",
    appId: "1:719716193156:web:bbbce04075f1ab09dd03d1",
    measurementId: "G-PJZDT5RN6E",
    databaseURL: "https://chatapp-ee2d2-default-rtdb.europe-west1.firebasedatabase.app"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

function ChatApp() {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });
        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        if (!currentUser) return;

        const messagesRef = ref(database, 'messages');
        onValue(messagesRef, (snapshot) => {
            const messagesData = snapshot.val();
            if (messagesData) {
                const messagesArray = Object.keys(messagesData).map(key => ({
                    id: key,
                    ...messagesData[key]
                }));
                setMessages(messagesArray);

                scrollToBottom();
            } else {
                setMessages([]);
            }
        });

        return () => { };
    }, [currentUser, database]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        const chatMessages = document.querySelector(".chat-messages");
        chatMessages.scrollTop = chatMessages.scrollHeight;
    };

    const handleInputChange = (event) => {
        setNewMessage(event.target.value);
    };

    const handleSendMessage = () => {
        if (!currentUser || newMessage.trim() === '') {
            console.log("User not authenticated or message is empty");
            return;
        }
        const messageData = {
            text: newMessage.trim(),
            sender: currentUser.uid,
            senderName: currentUser.displayName,
            timestamp: new Date().toISOString()
        };
        push(ref(database, 'messages'), messageData)
            .then(() => {
                console.log("Message sent successfully");
                setNewMessage('');
            })
            .catch((error) => {
                console.error("Error sending message: ", error);
            });
    };

    const signInWithGoogle = async () => {
        if (currentUser) {
            // If there's already a signed-in user, do nothing
            return;
        }
        const provider = new GoogleAuthProvider();
        try {
            // Sign in with Google
            const result = await signInWithPopup(auth, provider);
            setCurrentUser(result.user);
            scrollToBottom(); // Scroll to the bottom of the chat messages
        } catch (error) {
            console.error("Error signing in with Google: ", error);
        }
    };


    const handleSignOut = () => {
        signOut(auth).then(() => {
            setCurrentUser(null);
            window.location.reload(); // Refresh the page
        }).catch((error) => {
            console.error("Error signing out: ", error);
        });
    };

    const switchAccount = (account) => {
        setSelectedAccount(account);
        setCurrentUser(null); // Sign out current user
    };

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (
        <div className="chat-container">
            <div className={`user-menu ${menuVisible ? 'visible' : ''}`}>
                {currentUser ? (
                    <div>
                        <h2>Welcome, {currentUser.displayName}</h2>
                        <button className='Signin-button' onClick={handleSignOut}>Sign Out</button>
                    </div>
                ) : (
                    <button className='Signin-button' onClick={signInWithGoogle}>Sign In with Google</button>
                )}
            </div>
            <div className='menu-button-background'>
                <button className="menu-button" onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                    </svg>
                </button>
            </div>
            <div className="chat-messages">
                {messages.map(message => (
                    <div key={message.id} className={`message ${message.sender === currentUser?.uid ? 'sent' : 'received'}`}>
                        <p>{message.text}</p>
                        <small>From: {message.senderName}</small>
                        <small>{new Date(message.timestamp).toLocaleString()}</small>
                    </div>
                ))}
                <div className="chat-input">
                    <div className='textinput-input'>
                        <input
                            type="text"
                            placeholder="Type your message..."
                            value={newMessage}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='sendbutton-input'>
                        <button onClick={handleSendMessage}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" /></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatApp;